import React from "react";
import Switch from "react-switch";
import CurrencyInput from 'react-currency-input-field';

import { 
    Row,
    Col,
    Form,
} from "react-bootstrap-v5";

import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import InputLayout from "../../../../components/input/InputLayout";

import Utils from "../../../../utils/utils";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'


class PaymentSection extends React.Component {
  
    constructor(props) {
        super(props);
        
        this.name = 'job_form_payment_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        

        this.state = {
            id: id,
            row: row,

            isExpanded: true,
        };
    }

    
    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row className={''}>
            <Col xs={12} className={'mt-10 mb-10'}><hr /></Col>

            <Col xs={12} className={''}>
                <Row className={'align-items-center mb-7'}>
                    <Col xs={12} sm={'auto'}>
                        <h3>Payment</h3>
                    </Col>
                    <Col xs={12} sm={'auto'}>
                        <Switch 
                            disabled={Utils.isCompleted(values.currentStatusName)}
                            checked={values.isRequirePaymentCollection} 
                            onChange={async (checked ) => {
                                await setFieldValue('isRequirePaymentCollection', checked);
                            }}
                            handleDiameter={30}
                            uncheckedIcon={false}
                            checkedIcon={false}
                            onColor="#185cff"
                            boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
                            activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
                            height={20}
                            width={48}
                        />
                    </Col>
                </Row>
            </Col>

            {(values.isRequirePaymentCollection) && <Col xs={12} md={6}>
                <Row>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.amountToCollect.label}
                        >
                            <Form.Control
                                as = {CurrencyInput}
                                isInvalid={!!errors.amountToCollect}
                                
                                allowDecimals={true}
                                allowNegativeValue={false}
                                disableGroupSeparators={true}
                                prefix={Utils.getCurrency()}
        
                                id={this.props.fields.amountToCollect.id}
                                placeholder={this.props.fields.amountToCollect.placeholder}
                                readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                value={values.amountToCollect}
                                onValueChange={async (value) => {
                                    await setFieldValue('amountToCollect', value);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.amountToCollect}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                    {!values.isRecurringForm && <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.collectedAmount.label}
                        >
                            <Form.Control
                                as = {CurrencyInput}
                                isInvalid={!!errors.collectedAmount}
                                
                                allowDecimals={true}
                                allowNegativeValue={false}
                                disableGroupSeparators={true}
                                prefix={Utils.getCurrency()}
        
                                id={this.props.fields.collectedAmount.id}
                                placeholder={this.props.fields.collectedAmount.placeholder}
                                readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                value={values.collectedAmount}
                                onValueChange={async (value) => {
                                    await setFieldValue('collectedAmount', value);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.collectedAmount}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>}
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.paymentMethodId.label}
                        >
                            <Form.Control
                                as = {RSuiteSelectPicker}
                                isInvalid={!!errors.paymentMethodId}

                                id={this.props.fields.paymentMethodId.id}
                                placeholder={this.props.fields.paymentMethodId.placeholder}
                                items={values.customFormParams.paymentMethodItems}
                                value={values.paymentMethodId}
                                selectedName={values.paymentMethodName}
                                isLoading={values.customFormParams.paymentMethodIsLoading}
                                readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                searchable={false}
                                cleanable={true}
                                isDefault={true}
                                onSelect={async (value, item) => {
                                    await setFieldValue('paymentMethodId', value);
                                    await setFieldValue('paymentMethodName', item.title);
                                }}
                                onClear={async () => {
                                    await setFieldValue('paymentMethodId', null);
                                    await setFieldValue('paymentMethodName', '');
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.paymentMethodId}</Form.Control.Feedback>
                            <Form.Control.Feedback className={values.isFailedToCollect ? 'd-block' : 'd-none'} type="invalid">Driver failed to collect payment</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                </Row>
            </Col>}

        </Row>
    }
    

    render() {
        return <>
            {this.body()}
        </>
    }
}

const mapDispatchToProps = {
  dispatchApiCallGet,
  clear,
}

export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(PaymentSection);
