import React from "react";
import _ from "lodash";

import { 
    Row,
    Col,
    Form,
    Badge,
    Button,
    Dropdown,
} from "react-bootstrap-v5";
import styled from 'styled-components';

import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import RSuiteSelectPicker from '../../../../components/OLD/Select/RSuiteSelectPicker';
import CustomInlineGoogleMap from '../../../../components/OLD/GoogleMap/CustomInlineGoogleMap';
import CustomDialog from '../../../../components/dialog/CustomDialog';
import DropdownToggle from '../../../../components/dropdown/DropdownToggle';
import InputLayout from "../../../../components/input/InputLayout";
import PhoneField from '../../../../components/phone/PhoneField';

import JobRouteDialog from '../../Dialogs/JobRouteDialog';
import JobLocationDialog from '../../Dialogs/JobLocationDialog';

import Utils from "../../../../utils/utils";
import { JobStepType, CustomerSiteAvailability } from "../../../../utils/enums";

import { connect } from 'react-redux'
import { clear } from "../../../../../setup/redux/actions";
import { dispatchApiCallGet } from '../../../../../setup/redux/dispatch/actions'
import driverTrackingSlice from '../../redux/driverTrackingSlice'
import mapSlice from '../../../map/slice';


const DropdownToggleStyle = styled(DropdownToggle)`
    background-color: transparent !important;
`;


class LocationsSection extends React.Component {
  
    constructor(props) {
        super(props);

        this.refJobFormLocationDialog = React.createRef();
        
        this.name = 'job_form_locations_section';

        let dataParams = (props.dataParams) ? props.dataParams : null;
        let id = (dataParams && dataParams.id) ? dataParams.id : null;
        let row = (dataParams && dataParams.row) ? dataParams.row : null;
        

        this.state = {
            id: id,
            row: row,
            
            isOpenRouteDialog: false,
            routeDialogRow: null,

            currentPageCustomerSite: 1,
            totalPageCustomerSite: 0,
            queryStringCustomerSite: '',

            isLocationsDialog: false,
        };
    }


    componentDidMount(){
        let { values } = this.props.formOptions;
        this.props.action.driverTrackingSlice.callJobTrackingApi(values?.jobId, (state: Boolean, data: any) => {});

        if(!values.isEdit){
            this.props.action.mapSlice.callAllLatestLocationDriversApi([], (state: Boolean, data: any) => {});
        }
    }

    componentWillReceiveProps(nextProps) {
        // let markers = (nextProps && nextProps.formOptions && nextProps.formOptions && nextProps.formOptions.values && nextProps.formOptions.values.customFormParams && nextProps.formOptions.values.customFormParams.markers && nextProps.formOptions.values.customFormParams.markers.length > 0) ? nextProps.formOptions.values.customFormParams.markers : [];
        // this.setState({
        //     markers: markers
        // });
        
        this.reduxProps(nextProps);
    }


    /* API */
    reduxProps = nextProps => {
        Utils.reduxProps(nextProps,
            this.name + '-customersite', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;
                let total = 0;

                if(data && data.data && data.data.length > 0) {
                    total = data.total;

                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = item.customerSiteId;
                        // let title = item.siteNameDisplay;
                        let title = Utils.formatCustomerSiteAddress(item);
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArray(arr, value, arrItem);
                    }
                }

                this.setState({
                    totalPageCustomerSite: total,

                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );

        Utils.reduxProps(nextProps,
            this.name + '-jobtemplate_sites', 
            (data, isLoading, isError, err, statusCode, variables, callback) => {
                let arr = variables.items;

                if(data && data.data && data.data.length > 0) {
                    for (var i = 0; i < data.data.length; i++) {
                        let item = data.data[i];
                        let value = Utils.makeIDfromString(item.stepSiteName);
                        let title = item.stepSiteName;
                        let arrItem = {
                            value: value,
                            title: title,
                            item: item,
                        };

                        arr = Utils.addToArrayByTitle(arr, title, arrItem);
                    }
                }

                this.setState({
                    isLoading: isLoading,
                    isError: isError,
                    err: err,
                }, () => {
                    this.props.clear();

                    if(callback){
                        callback(arr);
                    }
                });
            }
        );
    }
	
    callReadCustomerSiteApi = (customerId = null, items, currentPage = null, callback = null) => {
        if(currentPage){
            this.setState({
                currentPageCustomerSite: currentPage,
            }, () => {
                let data = {
                    currentPage: this.state.currentPageCustomerSite,
                    pageSize: Utils.getDefaultPageSize(),
                    searchQuery: this.state.queryStringCustomerSite,
                    customerId: customerId,
                    showRecentJob: true,
                };
             
                this.props.dispatchApiCallGet(data, this.name + '-customersite', 'customersite', { items: items }, callback, () => {});
            });
        } else {
            let data = {
                currentPage: this.state.currentPageCustomerSite,
                pageSize: Utils.getDefaultPageSize(),
                searchQuery: this.state.queryStringCustomerSite,
                customerId: customerId,
                showRecentJob: true,
            };
         
            this.props.dispatchApiCallGet(data, this.name + '-customersite', 'customersite', { items: items }, callback, () => {});
        }
    }
    
    callReadJobTemplateSitesApi = (jobTemplateStepId = null, items, callback = null) => {
        let data = {
            currentPage: 1,
            pageSize: Utils.getMaxPageSize(),
            searchQuery: '',
            jobTemplateStepId: jobTemplateStepId,
        };
     
        this.props.dispatchApiCallGet(data, this.name + '-jobtemplate_sites', 'jobtemplate/get-job-template-site-address', { items: items }, callback, () => {});
    }
    /* END API */


    calcDistance = async () => {
        let {
            values,
            setFieldValue,
        } = this.props.formOptions;

        let markers = Utils.getJobFormLocationMarkers({
            customerSiteId: values.customerSiteId,
            customerSiteItems: values.customFormParams.customerSiteItems,
            customerSiteShiftId: values.customerSiteShiftId,
            customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
            templateStepSites1Latitude: values.templateStepSites1Latitude,
            templateStepSites1Longitude: values.templateStepSites1Longitude,
            templateStepSites2Latitude: values.templateStepSites2Latitude,
            templateStepSites2Longitude: values.templateStepSites2Longitude,
            jobTemplateType: values.customFormParams.jobTemplateType
        }, values)

        await setFieldValue('customFormParams.markers', markers);
    }


    body = () => {
        let {
            handleChange,
            setFieldValue,
            validateForm,
            values,
            errors,
        } = this.props.formOptions;
        
        return <Row className={'gx-custom'}>
            <Col xs={12} className={'mt-10 mb-10'}><hr /></Col>

            <Col xs={12} className={'mb-7'}>
                {
                (values.customFormParams.jobTemplateType === JobStepType.Shift)
                ?
                <h3 className={'mb-0'}>Locations</h3>
                :
                <Dropdown>
                    <Dropdown.Toggle as={DropdownToggleStyle} className={'p-0'}>
                        <h3 className={'mb-0'}>Locations <ArrowDropDownIcon /></h3>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            disabled={!(values.jobTemplateId > 0)}
                            onClick={() => {
                                this.setState({
                                    isLocationsDialog: true,
                                });
                            }}
                        >Edit Default Locations</Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
                }
                <Form.Control.Feedback className={'d-block'} type="invalid">{errors.templateStepSites1Id}</Form.Control.Feedback>
                <Form.Control.Feedback className={'d-block'} type="invalid">{errors.templateStepSites1Name}</Form.Control.Feedback>
                <Form.Control.Feedback className={'d-block'} type="invalid">{errors.templateStepSites2Id}</Form.Control.Feedback>
                <Form.Control.Feedback className={'d-block'} type="invalid">{errors.templateStepSites2Name}</Form.Control.Feedback>
            </Col>
            
            <Col xs={12} md={6}>
                <Row>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.customerSiteId.label + ((values.customFormParams.jobTemplateType === JobStepType.Shift) ? ' ' + this.props.fields.customerSiteId.info : '')}
                            required
                        >
                            <Form.Control
                                as = {RSuiteSelectPicker}
                                isInvalid={!!errors.customerSiteId || !!errors.hasCoordCustomerSite}

                                id={this.props.fields.customerSiteId.id}
                                placeholder={this.props.fields.customerSiteId.placeholder}
                                items={values.customFormParams.customerSiteItems}
                                value={values.customerSiteId}
                                selectedName={values.customerSiteName}
                                isLoading={values.customFormParams.customerSiteIsLoading}
                                readOnly={values.isParams || Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isStartedOrInProgressOrCompletedOrCancelledOrFailed(values.currentStatusName) || values.isCollectJob}
                                searchable={true}
                                cleanable={true}
                                virtualized={false}
                                isDefault={true}
                                renderExtraFooter={() => {
                                    let totalCount = Utils.getPaginationCount(this.state.totalPageCustomerSite, Utils.getDefaultPageSize());

                                    return <Row className={'align-items-center'}>
                                        
                                        {(((this.state.totalPageCustomerSite > 0) && (this.state.currentPageCustomerSite < totalCount))) && <Col xs={12}>
                                            <div className={'px-5 pt-3'}>
                                                <a href={'/'}
                                                    className={"link-primary"}
                                                    onClick={async (e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        await setFieldValue('customFormParams.customerSiteIsLoading', true);
                                                        this.callReadCustomerSiteApi(values.customerId, values.customFormParams.customerSiteItems, (this.state.currentPageCustomerSite + 1), async (items) => {
                                                            await setFieldValue('customFormParams.customerSiteItems', items);
                                                            await setFieldValue('customFormParams.customerSiteIsLoading', false);
                                                            Utils.scrollToBottomByClassName('ReactVirtualized__Grid');
                                                        });
                                                    }}
                                                >View more</a>
                                            </div>
                                        </Col>}

                                        <Col xs={12}>
                                            <hr />
                                        </Col>
                                        <Col xs={12}>
                                            <div className={'px-5 pb-3'}>
                                                <a href={'/'}
                                                    className={"link-primary"}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        e.stopPropagation();

                                                        if(this.props.onCreateCustomerSite){
                                                            this.props.onCreateCustomerSite(1);
                                                        }
                                                    }}
                                                >Create new</a>
                                            </div>
                                        </Col>
                                    </Row>
                                }}
                                onSearch={(val) => {
                                    this.setState({
                                        queryStringCustomerSite: val,
                                    }, async () => {
                                        await setFieldValue('customFormParams.customerSiteIsLoading', true);
                                        this.callReadCustomerSiteApi(values.customerId, [], 1, async (items) => {
                                            let item = values.customerSiteItem;
                                            if(item){
                                                let value = item.customerSiteId;
                                                // let title = item.siteNameDisplay;
                                                let title = Utils.formatCustomerSiteAddress(item);
                                                let arrItem = {
                                                    value: value,
                                                    title: title,
                                                    item: item,
                                                };
                                                items = Utils.addToArrayIfNotExist(items, value, arrItem);
                                            }

                                            await setFieldValue('customFormParams.customerSiteItems', items);
                                            await setFieldValue('customFormParams.customerSiteIsLoading', false);
                                        });
                                    });
                                }}
                                onClick={async () => {
                                    this.setState({
                                        queryStringCustomerSite: '',
                                    }, async () => {
                                        await setFieldValue('customFormParams.customerSiteIsLoading', true);
                                        this.callReadCustomerSiteApi(values.customerId, [], 1, async (items) => {
                                            let item = values.customerSiteItem;
                                            if(item){
                                                let value = item.customerSiteId;
                                                // let title = item.siteNameDisplay;
                                                let title = Utils.formatCustomerSiteAddress(item);
                                                let arrItem = {
                                                    value: value,
                                                    title: title,
                                                    item: item,
                                                };
                                                items = Utils.addToArrayIfNotExist(items, value, arrItem);
                                            }

                                            await setFieldValue('customFormParams.customerSiteItems', items);
                                            await setFieldValue('customFormParams.customerSiteIsLoading', false);
                                        });
                                    });
                                }}
                                onSelect={async (value, item) => {
                                    // Utils.onClearSteps(this.props.formOptions);
                                    Utils.onClearServices(this.props.formOptions);

                                    let title = (item && item.title && item.title != '') ? item.title : '';
                                    await setFieldValue('customerSiteId', value);
                                    await setFieldValue('customerSiteName', title);
                                        
                                    let latitude = (item && item.item && item.item.latitude && item.item.latitude != '') ? item.item.latitude : '';
                                    let longitude = (item && item.item && item.item.longitude && item.item.longitude != '') ? item.item.longitude : '';
                                    let hasCoord = ((latitude && latitude != '') && (longitude && longitude != '')) ? true : false;
                                    if(!hasCoord){
                                        Utils.toast('Customer site doesn\'t have coordinates, please add the missing coordinates.', 'warning', null, 4000);
                                    }

                                    await setFieldValue('hasCoordCustomerSite', hasCoord);

                                    let markers = Utils.getJobFormLocationMarkers({
                                        customerSiteId: value,
                                        customerSiteItems: values.customFormParams.customerSiteItems,
                                        customerSiteShiftId: values.customerSiteShiftId,
                                        customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                                        templateStepSites1Latitude: values.templateStepSites1Latitude,
                                        templateStepSites1Longitude: values.templateStepSites1Longitude,
                                        templateStepSites2Latitude: values.templateStepSites2Latitude,
                                        templateStepSites2Longitude: values.templateStepSites2Longitude,
                                        jobTemplateType: values.customFormParams.jobTemplateType
                                    }, values)
                                    await setFieldValue('customFormParams.markers', markers);

                                    if(item && item.item){
                                        let obj = item.item;
                                        let remarks = (obj && obj.remarks && obj.remarks !== '') ? obj.remarks : '';
                                        let instructions = (obj && obj.instructions && obj.instructions !== '') ? obj.instructions : '';
                                        let contactPersonName = (obj && obj.contactPersonName && obj.contactPersonName !== '') ? obj.contactPersonName : '';
                                        let contactPersonPhoneNumber = (obj && obj.contactPersonPhoneNumber && obj.contactPersonPhoneNumber !== '') ? obj.contactPersonPhoneNumber : '';
                                        let contactPersonNameTwo = (obj && obj.contactPersonNameTwo && obj.contactPersonNameTwo !== '') ? obj.contactPersonNameTwo : '';
                                        let contactPersonPhoneNumberTwo = (obj && obj.contactPersonPhoneNumberTwo && obj.contactPersonPhoneNumberTwo !== '') ? obj.contactPersonPhoneNumberTwo : '';
                                        
                                        let isContactPersonSendNotification = ((obj.isContactPersonSendNotification === false) || (obj.isContactPersonSendNotification === true)) ? obj.isContactPersonSendNotification : false;
                                        let isContactPersonTwoSendNotification = ((obj.isContactPersonTwoSendNotification === false) || (obj.isContactPersonTwoSendNotification === true)) ? obj.isContactPersonTwoSendNotification : false;
                                
                                        let copySiteRemarksToJob = (obj && ((obj.copySiteRemarksToJob == false) || (obj.copySiteRemarksToJob == true))) ? obj.copySiteRemarksToJob : false;
                                        
                                        let serviceId = (obj && obj.serviceId && obj.serviceId > 0) ? obj.serviceId : null;
                                        let serviceName = (obj && obj.serviceName && obj.serviceName !== '') ? obj.serviceName : '';
                                        
                                        let weekdaysAvailability = (obj && obj.weekdaysAvailability) ? obj.weekdaysAvailability : CustomerSiteAvailability.All;
                                        let weekendAvailability = (obj && obj.weekendAvailability) ? obj.weekendAvailability : CustomerSiteAvailability.All;
                                        
                                        let defaultDrivers = (obj.defaultDrivers && obj.defaultDrivers.length > 0) ? obj.defaultDrivers : [];
                                        if(defaultDrivers && defaultDrivers.length > 0){
                                            let defArr = defaultDrivers.filter(x => x.defaultVehicle !== null);
                                            if(defArr && defArr.length > 0){
                                                let driverItem = defArr[0];
                                                let driverId = (driverItem.driverId && driverItem.driverId > 0) ? driverItem.driverId : null;
                                                let driverName = (driverItem.driverName && driverItem.driverName !== '') ? driverItem.driverName : '';
                                                
                                                if(values.driverId === null || values.driverId <= 0){
                                                    let items = values.customFormParams.driverItems;
                                                    let arrItem = {
                                                        value: driverId,
                                                        title: driverName,
                                                        item: driverItem,
                                                    };
                                                    items = Utils.addToArray(items, driverId, arrItem);
                                                    await setFieldValue('customFormParams.driverItems', items);
                                                    
                                                    await setFieldValue('driverId', driverId);
                                                    await setFieldValue('driverName', driverName);
                                                }

                                                let vehicleItem = defArr[0].defaultVehicle;
                                                let vehicleId = (vehicleItem.vehicleId && vehicleItem.vehicleId > 0) ? vehicleItem.vehicleId : null;
                                                let vehicleName = (vehicleItem.vehicleName && vehicleItem.vehicleName !== '') ? vehicleItem.vehicleName : '';
                                                
                                                if(values.vehicleId === null || values.vehicleId <= 0){
                                                    let items = values.customFormParams.vehicleItems;
                                                    let arrItem = {
                                                        value: vehicleId,
                                                        title: vehicleName,
                                                        item: vehicleItem,
                                                    };
                                                    items = Utils.addToArray(items, vehicleId, arrItem);
                                                    await setFieldValue('customFormParams.vehicleItems', items);

                                                    await setFieldValue('vehicleId', vehicleId);
                                                    await setFieldValue('vehicleName', vehicleName);


                                                    let selectedStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','unassigned');
                                                    if (values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus.value) {
                                                        let newStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','assigned');
                                                        await setFieldValue('statusId', newStatus.value);
                                                        await setFieldValue('statusName', newStatus.title);
                                                    }
                                                    
                                                    await Utils.weighChitCalc(this.props.formOptions);
                                                }
                                            }
                                        }

                                        let prevRemarks = (values.customerSiteItem && values.customerSiteItem.remarks && values.customerSiteItem.remarks != '') ? values.customerSiteItem.remarks : '';
                                        let newRemarks = Utils.concatStringRemarks(values.remarks, remarks, prevRemarks, copySiteRemarksToJob);
                                        await setFieldValue('remarks', newRemarks);
                                        
                                        await setFieldValue('siteRemarks', instructions);
                                        await setFieldValue('contactPersonOne', contactPersonName);
                                        await setFieldValue('contactNumberOne', contactPersonPhoneNumber);
                                        await setFieldValue('contactPersonTwo', contactPersonNameTwo);
                                        await setFieldValue('contactNumberTwo', contactPersonPhoneNumberTwo);
                                        await setFieldValue('isContactPersonSendNotification', isContactPersonSendNotification);
                                        await setFieldValue('isContactPersonTwoSendNotification', isContactPersonTwoSendNotification);
                                        await setFieldValue('serviceId', serviceId);
                                        await setFieldValue('serviceName', serviceName);
                                        await setFieldValue('customFormParams.weekdaysAvailability', weekdaysAvailability);
                                        await setFieldValue('customFormParams.weekendAvailability', weekendAvailability);
                                        await setFieldValue('customerSiteAddress', Utils.formatCustomerSiteAddress(item.item));
                                        await setFieldValue('customerSiteItem', item.item);
                                    }
                                }}
                                onClear={async () => {
                                    // Utils.onClearSteps(this.props.formOptions);
                                    Utils.onClearServices(this.props.formOptions);

                                    await setFieldValue('customerSiteId', null);
                                    await setFieldValue('customerSiteName', '');
                                    await setFieldValue('customerSiteAddress', '');
                                    await setFieldValue('customerSiteItem', null);
                                    await setFieldValue('hasCoordCustomerSite', false);
                                    await setFieldValue('remarks', '');
                                        
                                    await setFieldValue('serviceId', null);
                                    await setFieldValue('serviceName', '');

                                    await setFieldValue('customFormParams.weekdaysAvailability', CustomerSiteAvailability.All);
                                    await setFieldValue('customFormParams.weekendAvailability', CustomerSiteAvailability.All);

                                    let markers = Utils.getJobFormLocationMarkers({
                                        customerSiteId: null,
                                        customerSiteItems: values.customFormParams.customerSiteItems,
                                        customerSiteShiftId: values.customerSiteShiftId,
                                        customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                                        templateStepSites1Latitude: values.templateStepSites1Latitude,
                                        templateStepSites1Longitude: values.templateStepSites1Longitude,
                                        templateStepSites2Latitude: values.templateStepSites2Latitude,
                                        templateStepSites2Longitude: values.templateStepSites2Longitude,
                                        jobTemplateType: values.customFormParams.jobTemplateType
                                    }, values)
                                    await setFieldValue('customFormParams.markers', markers);
                                }}
                            />
                            <Form.Control.Feedback type="invalid">{errors.customerSiteId}</Form.Control.Feedback>
                            <Form.Control.Feedback type="invalid">{errors.hasCoordCustomerSite}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.siteRemarks.label}
                        >
                            <Form.Control
                                // type="text"
                                as="textarea"
                                rows={5}
                                id={this.props.fields.siteRemarks.id}
                                placeholder={this.props.fields.siteRemarks.placeholder}
                                readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                value={values.siteRemarks}
                                onChange={async (e) => {
                                    Utils.limitRows(e.target.value, 5, async (value) => {
                                        await setFieldValue('siteRemarks', value);
                                    });
                                }}
                                isInvalid={!!errors.siteRemarks}
                            />
                            <Form.Control.Feedback type="invalid">{errors.siteRemarks}</Form.Control.Feedback>
                        </InputLayout>
                    </Col>
                </Row>
            </Col>

            <Col xs={12} md={6}>
                <Row>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.contactPersonOne.label}
                        >
                            <Row className={'align-items-center'}>
                                <Col xs={12} md={true}>
                                    <Form.Control
                                        type="text"
                                        id={this.props.fields.contactPersonOne.id}
                                        placeholder={this.props.fields.contactPersonOne.placeholder}
                                        readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                        value={values.contactPersonOne}
                                        onChange={handleChange}
                                        isInvalid={!!errors.contactPersonOne}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.contactPersonOne}</Form.Control.Feedback>
                                </Col>
                                <Col xs={12} md={true}>
                                    <Form.Control
                                        // as={PhoneField}
                                        id={this.props.fields.contactNumberOne.id}
                                        placeholder={this.props.fields.contactNumberOne.placeholder}
                                        value={values.contactNumberOne}
                                        isInvalid={!!errors.contactNumberOne}

                                        onChange={async (e) => {
                                            await setFieldValue('contactNumberOne', e.target.value);
                                            // await setFieldValue('contactNumberOne', Utils.parsePhoneNumber(value));
                                            
                                            // if(value == '' || value == undefined){
                                            //     await setFieldValue('isContactPersonSendNotification', false);
                                            // }
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.contactNumberOne}</Form.Control.Feedback>
                                </Col>
                                {/* {!values.isRecurringForm && <Col xs={12} md={'auto'}>
                                    <Form.Check
                                        id={this.props.fields.isContactPersonSendNotification.id}
                                        label={this.props.fields.isContactPersonSendNotification.label}
                                        checked={values.isContactPersonSendNotification}
                                        disabled={(values.contactNumberOne == '' || values.contactNumberOne == undefined)}
                                        onChange={async (e) => {
                                            await setFieldValue('isContactPersonSendNotification', e.target.checked);
                                        }}
                                        isInvalid={!!errors.isContactPersonSendNotification}
                                        feedback={errors.isContactPersonSendNotification}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.isContactPersonSendNotification}</Form.Control.Feedback>
                                </Col>} */}
                            </Row>
                        </InputLayout>
                    </Col>
                    <Col xs={12}>
                        <InputLayout
                            label={this.props.fields.contactPersonTwo.label}
                        >
                            <Row className={'align-items-center'}>
                                <Col xs={12} md={true}>
                                    <Form.Control
                                        type="text"
                                        id={this.props.fields.contactPersonTwo.id}
                                        placeholder={this.props.fields.contactPersonTwo.placeholder}
                                        readOnly={Utils.isRecurringFormAndisActive(values.isRecurringForm, values.isActive) || Utils.isCompletedOrCancelledOrFailed(values.currentStatusName)}
                                        value={values.contactPersonTwo}
                                        onChange={handleChange}
                                        isInvalid={!!errors.contactPersonTwo}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.contactPersonTwo}</Form.Control.Feedback>
                                </Col>
                                <Col xs={12} md={true}>
                                    <Form.Control
                                        // as={PhoneField}
                                        id={this.props.fields.contactNumberTwo.id}
                                        placeholder={this.props.fields.contactNumberTwo.placeholder}
                                        value={values.contactNumberTwo}
                                        isInvalid={!!errors.contactNumberTwo}

                                        onChange={async (e) => {
                                            await setFieldValue('contactNumberTwo', e.target.value);
                                            // await setFieldValue('contactNumberTwo', Utils.parsePhoneNumber(value));
                                            
                                            // if(value == '' || value == undefined){
                                            //     await setFieldValue('isContactPersonTwoSendNotification', false);
                                            // }
                                        }}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.contactNumberTwo}</Form.Control.Feedback>
                                </Col>
                                {/* {!values.isRecurringForm && <Col xs={12} md={'auto'}>
                                    <Form.Check
                                        id={this.props.fields.isContactPersonTwoSendNotification.id}
                                        label={this.props.fields.isContactPersonTwoSendNotification.label}
                                        checked={values.isContactPersonTwoSendNotification}
                                        disabled={(values.contactNumberTwo == '' || values.contactNumberTwo == undefined)}
                                        onChange={async (e) => {
                                            await setFieldValue('isContactPersonTwoSendNotification', e.target.checked);
                                        }}
                                        isInvalid={!!errors.isContactPersonTwoSendNotification}
                                        feedback={errors.isContactPersonTwoSendNotification}
                                    />
                                    <Form.Control.Feedback type="invalid">{errors.isContactPersonTwoSendNotification}</Form.Control.Feedback>
                                </Col>} */}
                            </Row>
                        </InputLayout>
                    </Col>
                </Row>
            </Col>

            <Col xs={12} className={'mt-5 mb-5'} style={{ minHeight: '280px' }}>
                <CustomInlineGoogleMap
                    isEdit={values.isEdit}
                    currentStatusName={values.currentStatusName}
                    isCustomerSIteSelected={(values.customerSiteId && values.customFormParams.jobTemplateDetails)}
                    driverId={values.driverId}
                    totalDistance={values.customFormParams.totalDistance}
                    distancesMarkers={values.customFormParams.markers}
                    driverLocation={this.props.driverTrackingSlice.items}
                    allDrivers={this.props.mapSlice.allDrivers}
                    markers={[Utils.getJobFormLocationCustomerMarker(this.props.formOptions)]}
                    center={Utils.getJobFormLocationCustomerMarker(this.props.formOptions)}
                    onDistance={async (distances) => {
                        if(distances && distances.length > 0){
                            await setFieldValue('customFormParams.totalDistance', distances[0]);
                        } else {
                            await setFieldValue('customFormParams.totalDistance', 0);
                        }
                    }}
                    onClickMarker={async (e, item, data) => {
                        let driver = (data && data.driver) ? data.driver : null;
                        let driverId = (driver && driver.driverId) ? driver.driverId : null;
                        let driverName = (driver && driver.driverName) ? driver.driverName : '';
                        let defaultVehicle = (driver && driver.defaultVehicle) ? driver.defaultVehicle : null;

                        await setFieldValue('driverId', driverId);
                        await setFieldValue('driverName', driverName);
                        
                        let items = values.customFormParams.driverItems;
                        let arrItem = {
                            value: driverId,
                            title: driverName,
                            item: driver,
                        };
                        items = Utils.addToArray(items, driverId, arrItem);
                        await setFieldValue('customFormParams.driverItems', items);

                        Utils.toast("Driver has been assigned", 'info'); 

                        if(values.vehicleId === null || values.vehicleId <= 0){
                            if(defaultVehicle){
                                let vehicleId = (defaultVehicle.vehicleId && defaultVehicle.vehicleId > 0) ? defaultVehicle.vehicleId : null;
                                let vehicleName = (defaultVehicle.vehicleName && defaultVehicle.vehicleName !== '') ? defaultVehicle.vehicleName : '';
                                // let unladednBinWeight = (defaultVehicle.unladednBinWeight) ? defaultVehicle.unladednBinWeight : 0;

                                let items = values.customFormParams.vehicleItems;
                                let arrItem = {
                                    value: vehicleId,
                                    title: vehicleName,
                                    item: defaultVehicle,
                                };
                                items = Utils.addToArray(items, vehicleId, arrItem);
                                await setFieldValue('customFormParams.vehicleItems', items);


                                await setFieldValue('vehicleId', vehicleId);
                                await setFieldValue('vehicleName', vehicleName);
                                // await setFieldValue('weighChitTareWeight', unladednBinWeight);

                                await Utils.weighChitCalc(this.props.formOptions);
                            }
                        }

                        let selectedStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','unassigned');
                        if (values.statusId === null || values.statusId === 0 || values.statusId === selectedStatus.value) {
                            let newStatus = Utils.findStatus(values.customFormParams.statusItems, 'title','assigned');
                            await setFieldValue('statusId', newStatus.value);
                            await setFieldValue('statusName', newStatus.title);

                            let disabledStatusItems = Utils.setStatusDisable(values.customFormParams.statusItems, values.currentStatusId, driverId, values.isEdit, values.isCopy, values.customFormParams.jobTemplateType);
                            await setFieldValue('customFormParams.disabledStatusItems', disabledStatusItems);
                        }
                    }}
                />
                <div
                    className={'px-5'}
                    style={{
                        position: 'relative',
                        top: '-55px',
                    }}
                >
                    <Row className={'align-items-end'}>
                        <Col xs={'auto'}>
                            <Badge className={'px-4 py-3'} bg={'white'} text={'primary'}>Total: {Utils.mToKm(values.customFormParams.totalDistance)}</Badge>
                        </Col>
                        <Col xs={true} className={'text-end'}>
                            <Button
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();

                                    let markers = Utils.getJobFormLocationMarkers({
                                        customerSiteId: values.customerSiteId,
                                        customerSiteItems: values.customFormParams.customerSiteItems,
                                        customerSiteShiftId: values.customerSiteShiftId,
                                        customerSiteShiftItems: values.customFormParams.customerSiteShiftItems,
                                        templateStepSites1Latitude: values.templateStepSites1Latitude,
                                        templateStepSites1Longitude: values.templateStepSites1Longitude,
                                        templateStepSites2Latitude: values.templateStepSites2Latitude,
                                        templateStepSites2Longitude: values.templateStepSites2Longitude,
                                        jobTemplateType: values.customFormParams.jobTemplateType
                                    }, values)
                                    setFieldValue('customFormParams.markers', markers);
                                    
                                    this.setState({
                                        isOpenRouteDialog: true,
                                        routeDialogRow: markers,
                                    });
                                }}
                            >Click to view route</Button>
                        </Col>
                    </Row>
                </div>
            </Col>
            
        </Row>
    }
    

    /* DIALOGS */
    setRouteDialog = () => {
        return <CustomDialog 
            title={'Job routes'}
            cancelText={'Cancel'}
            size={'xl'}
            body={<JobRouteDialog item={this.state.routeDialogRow} driverLocation={this.props.driverTrackingSlice.items} />}
            show={this.state.isOpenRouteDialog}
            isLoading={this.props.isLoading}
            onCancel={() => {
                this.setState({
                    isOpenRouteDialog: false,
                    routeDialogRow: null,
                });
            }}
        />
    }

    setLocationDialog = () => {
        return <CustomDialog 
            title={'Default Locations'}
            cancelText={'Cancel'}
            okText={'Save'}
            size={'md'}
            body={<JobLocationDialog 
                ref={this.refJobFormLocationDialog}
                formOptions={this.props.formOptions}
                fields={this.props.fields}
            />}
            show={this.state.isLocationsDialog}
            isLoading={this.props.isLoading}
            onCancel={async () => {
                let {
                    setFieldValue,
                } = this.props.formOptions;
                
                let state = null;
                if(this.refJobFormLocationDialog && this.refJobFormLocationDialog.current && this.refJobFormLocationDialog.current.state){
                    state = this.refJobFormLocationDialog.current.state;
                }
                
                let templateStepSites1Id = (state && state.templateStepSites1Id) ? state.templateStepSites1Id : null;
                let templateStepSites1Name = (state && state.templateStepSites1Name) ? state.templateStepSites1Name : '';
                let templateStepSites1Address = (state && state.templateStepSites1Address) ? state.templateStepSites1Address : '';
                let templateStepSites1Latitude = (state && state.templateStepSites1Latitude) ? state.templateStepSites1Latitude : null;
                let templateStepSites1Longitude = (state && state.templateStepSites1Longitude) ? state.templateStepSites1Longitude : null;

                let templateStepSites2Id = (state && state.templateStepSites2Id) ? state.templateStepSites2Id : null;
                let templateStepSites2Name = (state && state.templateStepSites2Name) ? state.templateStepSites2Name : '';
                let templateStepSites2Address = (state && state.templateStepSites2Address) ? state.templateStepSites2Address : '';
                let templateStepSites2Latitude = (state && state.templateStepSites2Latitude) ? state.templateStepSites2Latitude : null;
                let templateStepSites2Longitude = (state && state.templateStepSites2Longitude) ? state.templateStepSites2Longitude : null;

                await setFieldValue('templateStepSites1Id', templateStepSites1Id);
                await setFieldValue('templateStepSites1Name', templateStepSites1Name);
                await setFieldValue('templateStepSites1Address', templateStepSites1Address);
                await setFieldValue('templateStepSites1Latitude', templateStepSites1Latitude);
                await setFieldValue('templateStepSites1Longitude', templateStepSites1Longitude);
                
                await setFieldValue('templateStepSites2Id', templateStepSites2Id);
                await setFieldValue('templateStepSites2Name', templateStepSites2Name);
                await setFieldValue('templateStepSites2Address', templateStepSites2Address);
                await setFieldValue('templateStepSites2Latitude', templateStepSites2Latitude);
                await setFieldValue('templateStepSites2Longitude', templateStepSites2Longitude);

                
                this.setState({
                    isLocationsDialog: false,
                }, async () => {
                    await this.calcDistance();
                });
            }}
            onOk={async () => {
                // let {
                //     errors,
                // } = this.props.formOptions;

                // if(_.isEmpty(errors)){
                    this.setState({
                        isLocationsDialog: false,
                    }, async () => {
                        await this.calcDistance();
                    });
                // } else {
                //     Utils.toast('You must fill in the required fields', 'error');
                // }
            }}
        />
    }
    /* END DIALOGS */


    render() {
        return <>
            {this.body()}
            {this.setRouteDialog()}
            {this.setLocationDialog()}
        </>
    }
}


const mapStateToProps = state => {
    return {
      ...state,
      ...Utils.mapStateToProps(state),
    };
  };
  const mapDispatchToProps = (dispatch) => ({
    dispatchApiCallGet: (p1, p2, p3, p4, p5, p6) => dispatch(dispatchApiCallGet(p1, p2, p3, p4, p5, p6)),
    clear: (payload) => dispatch(clear(payload)),
    action: {
      driverTrackingSlice: {
        callJobTrackingApi: (payload, callback) => dispatch(driverTrackingSlice.callJobTrackingApi(payload, callback)),
      },
      mapSlice: {
        callAllLatestLocationDriversApi: (payload, callback) => dispatch(mapSlice.callAllLatestLocationDriversApi(payload, callback)),
      },
    }
  });
  
  
  export default connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(LocationsSection);