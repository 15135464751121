import React, { useState } from "react";
import _ from 'lodash';

import { compose, withProps, lifecycle, withHandlers } from "recompose";
import {
  GoogleMap,
  Marker,
  Polyline,
  withScriptjs,
  withGoogleMap,
} from "react-google-maps";

import { connect } from "react-redux";
import { dispatchApiCallPost, dispatchApiCallGet } from '../../../../setup/redux/dispatch/actions'
import { clear } from "../../../../setup/redux/actions";
import Utils from '../../../utils/utils'
import { AccountSettingType } from '../../../utils/enums'


export const changeMarkerColor = (statusDisplay, cls = '') => {
  let color = getColorByStatusName(statusDisplay);
  return encodeURIComponent(`<svg width="29" height="34" viewBox="0 0 29 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.5 14.0909C27.5 24.2727 14.5 33 14.5 33C14.5 33 1.5 24.2727 1.5 14.0909C1.5 6.861 7.3203 1 14.5 1C21.6797 1 27.5 6.861 27.5 14.0909Z" fill="${color}" stroke="${color}" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    <rect x="5.5" y="5" width="18" height="18" rx="9" fill="white"/>
    <g clip-path="url(#clip0_1160_3948)">
      <path d="M16.1667 15.6667V10.25H9.91675V15.6667H16.1667ZM16.1667 15.6667H19.0834V13.5833L17.8334 12.3333H16.1667V15.6667ZM12.8334 16.7083C12.8334 17.2836 12.367 17.75 11.7917 17.75C11.2165 17.75 10.7501 17.2836 10.7501 16.7083C10.7501 16.133 11.2165 15.6667 11.7917 15.6667C12.367 15.6667 12.8334 16.133 12.8334 16.7083ZM18.2501 16.7083C18.2501 17.2836 17.7837 17.75 17.2084 17.75C16.6331 17.75 16.1667 17.2836 16.1667 16.7083C16.1667 16.133 16.6331 15.6667 17.2084 15.6667C17.7837 15.6667 18.2501 16.133 18.2501 16.7083Z" stroke="${color}" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_1160_3948">
        <rect width="10" height="10" fill="white" transform="translate(9.5 9)"/>
      </clipPath>
    </defs>
  </svg>`)
}
export const getColorByStatusName = (statusDisplay) => {
  if(statusDisplay.toString().toLowerCase().trim().replace(' ', '') == 'started'){
    return '#185CFF';

  } else if(statusDisplay.toString().toLowerCase().trim().replace(' ', '') == 'inprogress'){
    return '#D318FF';

  } else if(statusDisplay.toString().toLowerCase().trim().replace(' ', '') == 'completed'){
    return '#00CB4A';

  } else {
    return '#7B7B7B';
  }
}


const { REACT_APP_GOOGLE_MAP_API_KEY } = process.env;
const URL = "https://maps.googleapis.com/maps/api/js?key=" + REACT_APP_GOOGLE_MAP_API_KEY + "&v=3.exp&libraries=geometry,drawing,places";


const DEF_CENTER = { lat: 1.353915, lng: 103.822901 };
const DEF_ZOOM = 16

         
const AuditTrailJobFormGoogleMap = compose(
  withProps({
    googleMapURL: URL,
    loadingElement: <div style={{ width: "100%", height: "100%" }} />,
    containerElement: <div style={{ width: "100%", height: "100%" }} />,
    mapElement: <div style={{ width: "100%", height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap,
  withHandlers((props) => {
    return {
      callReadAccountSettingsLatTypeApi: () => (callback) => {
        props.dispatchApiCallGet(null, 'map_route-account_setting_type_lat', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLatitude, null, callback, () => {});
      },
      callReadAccountSettingsLngTypeApi: () => (callback) => {
        props.dispatchApiCallGet(null, 'map_route-account_setting_type_lng', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionLongitude, null, callback, () => {});
      },
      callReadAccountSettingsZoomTypeApi: () => (callback) => {
        props.dispatchApiCallGet(null, 'map_route-account_setting_type_zoom', 'AccountSetting/type/' + AccountSettingType.DefaultMapPositionZoom, null, callback, () => {});
      }
    }
  }),
)((props) => {
  const mapRef = React.useRef(null);

  const [center, setCenter] = useState(props.center ? props.center : null);
  const [defaultCenter, setDefaultCenter] = useState(props.defaultCenter ? props.defaultCenter : null);
  const [defaultZoom, setDefaultZoom] = useState(props.defaultZoom ? props.defaultZoom : DEF_ZOOM);
  
  const [streetViewControl] = useState(((props.streetViewControl === false) || (props.streetViewControl === true)) ? props.streetViewControl : false);
  const [mapTypeControl] = useState(((props.mapTypeControl === false) || (props.mapTypeControl === true)) ? props.mapTypeControl : false);

  const [driverLocation, setDriverLocation] = useState((props.driverLocation && props.driverLocation.length > 0) ? props.driverLocation : []);


  React.useEffect(() => {
    props.callReadAccountSettingsLatTypeApi((latitude) => {
      props.callReadAccountSettingsLngTypeApi((longitude) => {
        props.callReadAccountSettingsZoomTypeApi((zoomItem) => {
          let lat = (latitude && latitude.value && latitude.value != '') ? latitude.value : DEF_CENTER.lat;
          let lng = (longitude && longitude.value && longitude.value != '') ? longitude.value : DEF_CENTER.lng;
          let zoom = (zoomItem && zoomItem.value && zoomItem.value != '') ? zoomItem.value : '';
          
          let defCenter = (lat != '' && lng != '') ? { lat: parseFloat(lat), lng: parseFloat(lng) } : DEF_CENTER;
          let defZoom = (zoom != '' && zoom != '') ? parseInt(zoom) : DEF_ZOOM;
          
          setDefaultZoom(defZoom);
          setDefaultCenter(defCenter);
        });
      });
    });
  }, []);


  React.useEffect(() => {
    Utils.reduxProps(props,
      'map_route-account_setting_type_lat', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        if(callback){
          callback(data);
        }

        props.clear();
      }
    );

    Utils.reduxProps(props,
      'map_route-account_setting_type_lng', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        if(callback){
          callback(data);
        }

        props.clear();
      }
    );

    Utils.reduxProps(props,
      'map_route-account_setting_type_zoom', 
      (data, isLoading, isError, err, statusCode, variables, callback) => {
        if(callback){
          callback(data);
        }

        props.clear();
      }
    );
  }, [props]);


  React.useEffect(() => {
    let items = (props.driverLocation && props.driverLocation.length > 0) ? props.driverLocation : [];
    setDriverLocation(items);

    if(items && items.length > 0){
      let currentStatus = (props.item && props.item.currentStatus && props.item.currentStatus != '') ? props.item.currentStatus : null;
      let itm = items.find((x: any) => x.statusDisplay === currentStatus)
      if(itm){
        let position = { lat: itm.latitude, lng: itm.longitude };
        setCenter(position);
        Utils.dinamicZoom(mapRef.current, position.lat, position.lng, 0.0020);
      }
    }
  }, [ props.driverLocation ]);


  const getDriverLocationsPath = () => {
    let countStarted = 0;
    let countInProgress = 0;
    let countCompleted = 0;
    let state = false;
    let zIndex = 0;

    return <>
      <Polyline
        path={driverLocation.map((item, i) => ({ lat: item.latitude, lng: item.longitude }))}
        options={{
          strokeColor: "#1253f9",
          strokeOpacity: 1.0,
          strokeWeight: 11,
        }}
      />
      {driverLocation.map((item, i) => {
        if((countStarted === 0) && (item.statusDisplay.toString().toLowerCase().trim().replace(' ', '') === 'started')){
          countStarted = countStarted + 1;
          state = true;
          zIndex = 1;
        } else if((countInProgress === 0) && (item.statusDisplay.toString().toLowerCase().trim().replace(' ', '') === 'inprogress')){
          countInProgress = countInProgress + 1;
          state = true;
          zIndex = 2;
        } else if((countCompleted === 0) && (item.statusDisplay.toString().toLowerCase().trim().replace(' ', '') === 'completed')){
          countCompleted = countCompleted + 1;
          state = true;
          zIndex = 3;
        } else {
          state = false;
          zIndex = 0;
        }

        if(state){
          let position = { lat: item.latitude, lng: item.longitude };
          let toPosition = driverLocation[i + 1] ? { lat: driverLocation[i + 1]?.latitude, lng: driverLocation[i + 1]?.longitude } : null;

          return <Marker
            key={'driver_location_marker_' + i}
            position={position}
            title={'Updated: ' + item.updatedDisplay}
            icon={{
              url: 'data:image/svg+xml;utf-8,' + changeMarkerColor(item.statusDisplay),
              scaledSize: new window.google.maps.Size(26, 32),
              labelOrigin: new window.google.maps.Point(13, 51),
            }}
            label={{
              text: item.statusDisplay + '\n\r' + item.updatedDisplay,
              color: "white",
              className: "marker-label-newline marker-label-newline-" + item.statusDisplay.toString().toLowerCase().trim().replace(' ', ''),
            }}
            zIndex={zIndex}
          />
        }
      })}
    </>
  }


  const googleMap = () => {
    if (typeof window.google !== 'undefined' && typeof window.google.maps !== 'undefined') {
      return (
        <div>
          <GoogleMap
            ref={mapRef}
            defaultZoom={defaultZoom}
            defaultCenter={defaultCenter}
            center={center ? center : defaultCenter}
            defaultOptions={{
              disableDefaultUI: true,
              disableDoubleClickZoom: true,
              rotateControl: false,
              zoomControl: false,
              cameraControl: false,
              fullscreenControl: true,
              streetViewControl: streetViewControl,
              streetViewControlOptions: {
                position: 7,
              },
              mapTypeControl: mapTypeControl,
            }}
          >
            {getDriverLocationsPath()}
          </GoogleMap>
        </div>
      );
    }
  };

  return googleMap();
});


const mapDispatchToProps = {
  dispatchApiCallPost,
  dispatchApiCallGet,
  clear,
}
export default connect(Utils.mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(AuditTrailJobFormGoogleMap);
